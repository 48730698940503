<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-select allowClear style="width:150px;margin:5px;"  @search='searchBigData' ref='addse' v-model="searchParams.id" :filter-option="untils.filterOption"  showSearch placeholder='请选择大币种' >
                    <a-select-option
                        v-for="item of currency_big_list"
                        :key="item.id" :value='item.id'
                    >{{item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' )}}</a-select-option>
                </a-select>

                <a-input v-model="searchParams.versionName" size='small' style="width:150px;" placeholder='版本或小币种'></a-input>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            
        </div>
        <div class="table-content" style="padding-top:20px;">

            <a-table 
                    size="middle"
                    v-if="data.length > 0"
                    @change="changePage" :pagination='pagination' 
                    :columns="columns"
                    :data-source="data"
                    :row-selection="rowSelection"
                    :defaultExpandAllRows="true"
                    :expanded-row-keys.sync="expandedRowKeys"
                    @expandedRowsChange="expandedRowsChange"
                    
            >
            <div slot="images" slot-scope="row">
                    <div style='max-width:100px;max-height:100px;display:flex;justify-content:center;align-items:center;'>
                        <img @click="previewImg(row)" style='max-width:100px;max-height:100px;' :src='row.coinImagesArray[0]'/>
                    </div>
            </div>

            <div slot="setup" slot-scope="row">
                <a @click="openModal(row)" style='margin-right:5px'>移动</a>
                <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.coinKindVersionId,row.versionGoodsId)"
                    >
                <a style='margin-left:5px'>删除</a>
                </a-popconfirm>
            </div>    
            </a-table>
            
        </div>

        <a-modal centered :width='500' v-model="showModal" @ok='checkForms' @cancel='cancel'>
            <div style="height:250px;overflow-y:auto;">
                <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
                    <a-form-model-item label='小币种' prop='coinKindItemId'>
                        <a-select @change="changeSelect"  ref='addse' v-model="forms.coinKindItemId" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                            <a-select-option v-for="item of currency_small_list" :key="item.id" :value='item.id'>{{item.coinKindItemName}}</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label='版别' prop='coinKindVersionId'>
                        <a-select @change="changeVersionSelect"  ref='addse' v-model="forms.coinKindVersionId" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                            <a-select-option v-for="item of currency_version_list" :key="item.id" :value='item.id'>{{item.coinKindVersionName}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    

                </a-form-model>
            </div>
        </a-modal>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment';
import 'moment/locale/zh-cn';
import {getBigCoinList} from "@/axios/apis"
export default {
    data(){
        return {
            innerText:'123321',
            columns:[
                {title:'ID',dataIndex:'id',key:'id',},
                {title:'大币种',dataIndex:'coinKindName',key:'coinKindName',},,
                {title:'小币种',dataIndex:'coinKindItemName',key:'coinKindItemName',},
                {title:'版别',dataIndex:'coinKindVersionName',key:'coinKindVersionName',},
                {title:'评级机构',dataIndex:'coinKindItemVersionGoodsCompany',key:'coinKindItemVersionGoodsCompany',},
                {title:'尺寸',dataIndex:'measure',key:'measure',},
                {title:'价格',dataIndex:'coinKindItemVersionGoodsPrice',key:'coinKindItemVersionGoodsPrice',
                    customRender:(text)=>{
                        const inner = text
                        if(inner){
                            return <span style='color:red'>￥{inner}</span>
                        }
                    }
                },
                {title:'图片',scopedSlots:{customRender:'images'}},
                
                {title:'时间',dataIndex:'coinKindItemVersionGoodsDealTime',key:'coinKindItemVersionGoodsDealTime',},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
         
            data:[],
            expandedRowKeys:[],
            selectedRowKeys:[],
            selectedRows:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            
            forms:{
                coinKindItemId:'',
                coinKindVersionId:'',
                versionGoodsId:'',
                targetKindVersionId:'',
                selectIds:[],
            },
            
            rules:{
                coinKindSid:[{ required: true, message: 'please change', trigger: 'change' }],
                coinKindVersionName:[{ required: true, message: 'please enter coinKindVersionName', trigger: 'blur' }],
                coinKindItemSid:[{ required: true, message: 'please enter coinKindItemName', trigger: 'blur' }],
            },
            tagRules:{
                coinTagCategory:[{ required: true, message: 'please change', trigger: 'change' }],
                coinTagName:[{ required: true, message: 'please change', trigger: 'change' }], 
            },
            currency_small_list:[],
            currency_version_list:[],
            currency_big_list:[],
            bottles:[],
            showModal:false,
            innerTitle:'',
            currency_type_list:[],
            coinKindName:'',
            sid:'',
            kindId:'',
            coinKindItemId:'',
            coinKindVersionId:'',
            page:1,
            limit:2,
            params:{
                page:1,
                limit:2,
                id:'',
                versionName:'',
            },
            
            pagination:{
                total:0,
                current:1,
                pageSize:2,
                'show-quick-jumper':true,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,

            searchParams:{
                page:1,
                limit:2,
                id:'',
                versionName:'',
            },
            
        }
    },
    computed:{
        ...mapState(['ip'])
    },
    created(){
        // this.searchBigData()
        this.getData(this.params)
    },
    computed: {
        rowSelection() {
            return {
                selectedRowKeys:this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys
                    this.selectedRows = selectedRows;
                    this.forms.selectIds = this.selectedRowKeys;
                },
            };
        },
    },
    
    methods:{
        openModal(row){
            this.showModal = true
            this.getCurrencySmallList({kindId:this.kindId})
            this.forms.coinKindItemId = row.coinKindItemId
            this.coinKindItemId = row.coinKindItemId
            this.getCurrencyVersionList({coinKindItemId:this.coinKindItemId})
            this.forms.coinKindVersionId = row.coinKindVersionId
            
            this.forms.versionGoodsId = row.versionGoodsId
            this.forms.targetKindVersionId = row.coinKindVersionId
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields();
        },
        async getData(){
            this.loading = true
            const res = await this.axios('/dq_admin/v2/kind/getByKindSid',{params:this.params})
            this.loading = false
            this.kindId = res.data.id
            this.coinKindName = res.data.coinKindName
            this.data = res.data.versionList
            this.pagination.total = res.data.totalPage*this.pagination.pageSize
            
            // this.expandedRowKeys = this.data[0].id//默认数据的ID 即可
            this.expandedRowKeys = [...Array(this.pagination.pageSize).keys()]
        },
        expandedRowsChange (expandedRows) {
            this.expandedRowKeys = expandedRows
        },
        selectChangeClick(selectedRowKeys, selectedRows){
            this.forms.selectIds = selectedRowKeys;
        },
    
        search(){
            const strs = this.untils.setParams(this.searchParams)
            this.params.conditions = strs
            this.params.page = 1
            this.params.id = this.searchParams.id
            this.params.versionName = this.searchParams.versionName
            this.getData(this.params)
        },
        getCurrencySmallList(params) {
            this.axios("/dq_admin/v2/kindItem/listByKindId", {
            params: params,
        }).then((res) => {
            let list = res.data;
            this.currency_small_list = list;
            });
        },
        getCurrencyVersionList(params) {
            this.axios("/dq_admin/v2/kindItem/listVersionByItemId", {
            params: params,
        }).then((res) => {
            let list = res.data;
            this.currency_version_list = list;
            });
        },
        async remove(coinKindVersionId,versionGoodsId){
            const res = await this.axios('/dq_admin/v2/kind_item_version_goods/delGoodsOrVersion',{params:{coinKindVersionId:coinKindVersionId,versionGoodsId:versionGoodsId}})
            if(res.status == '200'){
                this.$message.success(res.message)
                this.getData(this.params)
            }
        },
    
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.page = current
            
            this.params.limit = this.limit
            
            this.getData(this.params)
        },
        changeSelect(e){
            this.forms.coinKindItemId = e
            this.coinKindItemId = e
            this.getCurrencyVersionList({coinKindItemId:this.coinKindItemId})
        },
        changeVersionSelect(e){
            this.forms.coinKindVersionId = e
        },
        
        async searchBigData(inner){
          const res = await getBigCoinList(inner)
          if (res.status !== '200') return;
          const {records} = res.data
          this.currency_big_list = records
          this.$forceUpdate()
        },

        async submit(){
            const res = await this.axios.post('/dq_admin/v2/kind_item_version_goods/editGoodsOrVersion',this.forms)
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
                this.showModal = false
                this.cancel()
            }
        },
        cancel(){
            this.forms = {coinKindItemId:'',coinKindVersionId:'',versionGoodsId:'',targetKindVersionId:'',selectIds:[]}
            
            this.selectedRows = [];
            this.selectedRowKeys = [];
            this.$refs.ruleForm.resetFields();
        },
        checkForms(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        previewImg(row){
            if(row.coinImagesArray&&row.coinImagesArray.length>0){
                this.$previewImg({
                    list:row.coinImagesArray.reduce((pre,cur)=>{
                        const obj = {img_url:cur}
                        pre = pre.concat(obj)
                        return pre
                    },[]),
                    
                    baseImgField:'img_url',
                    showMute:false,
                })
            }
        },
        testBlur(e){
        },
        
    }
}

</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>